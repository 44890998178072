import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Party.css";
import { FaRegUserCircle } from "react-icons/fa";
import PartyArr from "./PartyArry";
import { BASE_URL, serverInstance } from "../../../axiosInsatnce";
import { ChatState } from "../../../Context/ChatProvider";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { FaLongArrowAltLeft, FaLongArrowAltRight } from "react-icons/fa";
import Swal from "sweetalert2";
import { LuShoppingCart } from "react-icons/lu";
import { TbListDetails } from "react-icons/tb";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";

const Saved = () => {
    const navigate = useNavigate();
    const { user } = ChatState();
    const [card, setcard] = useState([]);
    const [itemOffset, setItemOffset] = useState(0);
    let itemsP = 10;
    // Simulate fetching items from another resources.
    // (This could be items from props; or items loaded in a local state
    // from an API endpoint with useEffect and useState)
    const endOffset = itemOffset + itemsP;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    const currentItems = card.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(card.length / itemsP);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsP) % card.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };
    useEffect(() => {
        console.log(user, "user");
        if (user?._id) {
            serverInstance
                .get(`/item/saved/${user?._id}`)
                .then((res) => {
                    console.log(res);
                    setcard(res.data);
                })
                .catch((err) => {
                    console.log(err, "err");
                });
        }
    }, []);
    const Saved = (item) => {
        console.log("item", item);
        serverInstance
            .delete(`/item/saved/${item?._id}`)
            .then((res) => {
                console.log(res);
                setcard(card.filter((d) => d._id != item._id));
                Swal.fire("Item deleted from Saved", "", "success");
            })
            .catch((err) => {
                console.log(err, "err");
                Swal.fire(err?.response?.data?.message, "", "error");
            });
    };

    return (
        <div>
            <Header />
            <div className="PartyMain">
                <div className="Return-btn">
                    <Link to={"/RenteeMain"}>
                        <div className="MainBtn">Return</div>
                    </Link>
                </div>
                <div className="MainShade-box">
                    <div className="Heading" style={{ color: "white" }}>
                        Your favorite items
                    </div>
                </div>

                {card.length == 0 ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            fontSize: "20px",
                            fontWeight: "bold",
                        }}
                    >
                        There is no item for this
                    </div>
                ) : (
                    <div className="PartyCards">
                        {currentItems?.map((item, arr) => (
                            <div className="FirstPartyCard">
                                <div className="ImgPrnt">
                                    <div className="StyleCheckBox">
                                        <div className="clrCheckBox"></div>
                                    </div>

                                    <div className="ShadeImg">
                                        <div>
                                            <img
                                                className="SecPartyMainImg"
                                                src={BASE_URL + "/" + item?.item?.image[0]}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="Content">
                                    <div onClick={() => Saved(item)} className="item_save_button">
                                        Un Save
                                        <div>
                                            <LuShoppingCart />
                                        </div>
                                    </div>
                                    {console.log("logger", card)}
                                    <div
                                        className="item_save_button"
                                        onClick={() => {
                                            navigate(`/item/${item?.item?._id}`, {
                                                replace: true,
                                                state: { data: item?.item },
                                            });
                                        }}
                                    >
                                        Detail
                                        <div>
                                            <TbListDetails />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="ContentName">{item?.item?.name}</div>
                                        <div className="ContentDesc">{item?.item?.description}</div>
                                    </div>
                                    <div className="ContentPrice">
                                        Daily rental rate:${item?.item?.dailyPrice}
                                    </div>
                                    <div className="SenderDetail">
                                        <div>
                                            <FaRegUserCircle className="ImgChat" size="30" />
                                        </div>
                                        <div>{item.user.userName}</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {/* </div>
            );
          })} */}
                        <div className="d-flex justify-content-center">
                            <ReactPaginate
                                // previousLabel={"←"}
                                previousLabel={
                                    <FaLongArrowAltLeft
                                        size={55}
                                        style={{ marginTop: "-45px" }}
                                        color={"#cc5500"}
                                    />
                                }
                                nextLabel={
                                    <FaLongArrowAltRight
                                        size={55}
                                        style={{ marginTop: "-45px" }}
                                        color={"#cc5500"}
                                    />
                                }
                                pageCount={pageCount}
                                pageRangeDisplayed={2}
                                marginPagesDisplayed={1}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                previousLinkClassName={"pagination__link"}
                                nextLinkClassName={"pagination__link"}
                                disabledClassName={"pagination__link--disabled"}
                                activeClassName={"pagination__link--active"}
                            />
                        </div>
                    </div>
                )}
            </div>
            <Footer />
        </div>
    );
};

export default Saved;
