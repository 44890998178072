import React, { useState } from "react";
import "./Register.css";
import LoginMain from "../../Images/newbg.jpg";
import { FiFacebook } from "react-icons/fi";

import { CiInstagram } from "react-icons/ci";
import { CiTwitter } from "react-icons/ci";
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";
import { useNavigate, Link } from "react-router-dom";
import { serverInstance } from "../../../axiosInsatnce";
import logo from "../../Images/logo.png";
import Swal from "sweetalert2";
function Register() {
  let navigate = useNavigate();
  const [state, setState] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    userName: "",
  });
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [isRegister, setIsregister] = useState(true);
  const [isLogin, setIsLogin] = useState();
  function validate_password(password) {
    let check =
      /^(?=[^A-Z\n]*[A-Z])(?=[^a-z\n]*[a-z])(?=[^0-9\n]*[0-9])(?=[^#?!@$%^&*\n-]*[#?!@$%^&*-]).{8,}$/;
    if (state.password !== state.confirmPassword) {
      Swal.fire("password and confirm password should be same!", "", "error");
      console.log("Meh, not so much.");
      return false;
    } else {
      if (password.match(check)) {
        console.log("Your password is strong.");

        return true;
      } else {
        Swal.fire("Enter strong passwords", "", "error");
        console.log("Meh, not so much.");
        return false;
      }
    }
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (state.email == "") return Swal.fire("Enter a valid email", "", "error");
    if (state.userName == "")
      return Swal.fire("Enter a full name", "", "error");
    if (state.password == "")
      return Swal.fire("Enter a valid password", "", "error");
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/.test(state.email)) {
      // if (validate_password(state.password)) {
      if (true) {
        try {
          await serverInstance.post("/user/signup", state);

          Swal.fire("Sign up successfully", "", "success");
          navigate("/RenteeMain");
        } catch (err) {
          console.log(err);
          Swal.fire(
            err.response.data.message || "Something went wrong!",
            "",
            "error"
          );
        }
      }
    } else return Swal.fire("Enter a valid Email", "", "error");
  };
  const handleLoginPage = () => {
    setIsregister(false);
    setIsLogin(true);
  };
  const handleRegisterPage = () => {
    setIsregister(true);
    setIsLogin(false);
  };

  return (
    <>
      <div className="RegisterMain">
        <>
          <div className="ImgPortion">
            <img className="Img-back" src={LoginMain} />
            <div className="LeftHead">
              <div className="Login-Head">
                <img src={logo} alt="" width={"250px"} />
              </div>
              {/* <div className="Member">Be a Member</div>
              <div className="RegisterContent">
                We're excited to have you join our community. By creating an
                account, you're unlocking a world of possibilities and
                opportunities.
              </div> */}

              {/* <div className="PortionIcons">
                <div className="borderIcon">
                  <FiFacebook className="imageIcons" />
                </div>
                <div className="borderIcon">
                  <CiInstagram className="imageIcons" />
                </div>
                <div className="borderIcon">
                  <CiTwitter className="imageIcons" />
                </div>
              </div> */}
            </div>
          </div>

          <div className="TextPortion">
            <div className="RightPortion">
              <div className="TextHead">Register</div>
            </div>

            <div className="InputPortion">
              <div className="BarsPortion">
                <input
                  className="InputBars"
                  placeholder="Fullname"
                  onChange={(e) => {
                    setState({ ...state, userName: e.target.value });
                  }}
                ></input>
                <input
                  className="InputBars"
                  placeholder="Email"
                  value={state.email}
                  onChange={(e) => {
                    setState({ ...state, email: e.target.value });
                  }}
                />
                <div className="InputBars">
                  <input
                    className="pass-Input"
                    type={show == true ? "text" : "password"}
                    placeholder="Password"
                    value={state.password}
                    onChange={(e) => {
                      setState({ ...state, password: e.target.value });
                    }}
                  />
                  <i className="pass-Icon">
                    {show == true ? (
                      <span
                        onClick={() => {
                          setShow(false);
                        }}
                      >
                        {" "}
                        <AiFillEyeInvisible />
                      </span>
                    ) : (
                      <span
                        onClick={() => {
                          setShow(true);
                        }}
                      >
                        <AiFillEye />
                      </span>
                    )}
                  </i>
                </div>
                <div className="InputBars">
                  <input
                    className="pass-Input"
                    type={show == true ? "text" : "password"}
                    placeholder="Confirm Password"
                    value={state.confirmPassword}
                    onChange={(e) => {
                      setState({ ...state, confirmPassword: e.target.value });
                    }}
                  />
                  <i className="pass-Icon">
                    {show1 == true ? (
                      <span
                        onClick={() => {
                          setShow1(false);
                        }}
                      >
                        {" "}
                        <AiFillEyeInvisible />
                      </span>
                    ) : (
                      <span
                        onClick={() => {
                          setShow1(true);
                        }}
                      >
                        <AiFillEye />
                      </span>
                    )}
                  </i>
                </div>
              </div>

              <div className="LinkBtn">
                <Link to={"/LoginPage"}>
                  <div className="LinkBtn-Text">Login?</div>
                </Link>
              </div>
            </div>

            <div className="registerBtn">
              <button className="TextBtn" onClick={handleSubmit}>
                Register
              </button>
            </div>
          </div>
        </>
      </div>
    </>
  );
}

export default Register;
