import React, { useState, useEffect } from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { useLocation } from "react-router-dom";
import { serverInstance } from "../../axiosInsatnce";
import ItemsList from "./Party/ItemsList";

function SearchItems() {
  const location = useLocation();

  const [cards, setcard] = useState([]);

  useEffect(() => {
    serverInstance
      .get(`/item/search?query=${location?.state?.data}`)
      .then((res) => {
        setcard(res.data);
        console.log("logger", res);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  }, [location]);

  console.log("logger", cards);
  return (
    <>
      <Header />
      <ItemsList
        cards={cards}
        //   hede={location.state.d}
      />
      <Footer />
    </>
  );
}

export default SearchItems;
