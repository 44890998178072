import React from 'react'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import Profile from './Profile/Profile'

function ProfileMain() {
  return (
    <>
    <Header/>
   <Profile/>
   <Footer/>
   </>
  )
}

export default ProfileMain
