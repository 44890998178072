
import PartyMainImg from '../../Images/PartyMainImg.png'

import chatIcon from '../../Images/chatIcon.png'


const SecCardArr = [[
    {
        image : PartyMainImg,
        name : 'Shees',
        content : 'Lorem asdjkjasdnkj jaksdhkjas dajks askjdasjk jkasdjkas',
        price : '$323.32 per day',
        icon : chatIcon,
        chatName : 'Shees'        
    }, 
    {
        image : PartyMainImg,
        name : 'Shees',
        content : 'Lorem asdjkjasdnkj jaksdhkjas dajks askjdasjk jkasdjkas',
        price : '$323.32 per day',
        icon : chatIcon,
        chatName : 'Shees'     
        
    },
    {
        image : PartyMainImg,
        name : 'Shees',
        content : 'Lorem asdjkjasdnkj jaksdhkjas dajks askjdasjk jkasdjkas',
        price : '$323.32 per day',
        icon : chatIcon,
        chatName : 'Shees'     
        
    }
    ,
    {
        image : PartyMainImg,
        name : 'Shees',
        content : 'Lorem asdjkjasdnkj jaksdhkjas dajks askjdasjk jkasdjkas',
        price : '$323.32 per day',
        icon : chatIcon,
        chatName : 'Shees'     
        
    }
]]



export default SecCardArr;
