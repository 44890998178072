import "./App.css";
import React, { useState, useEffect } from "react";

import Homemain from "./Components/HomeMain/Homemain";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import ChatHome from "./Components/ChatMain/ChatHome/ChatHome";
import PickupMain from "./Components/PickupMain/PickupMain";
import ReturnLocMain from "./Components/ReturnLocationMain/ReturnLocMain";
import Confirmation from "./Components/ConfirmationMain/Confirmation";
import RatingMain from "./Components/Rating/RatingMain";
import ReviewMain from "./Components/ReviewMain/ReviewMain";
import PaymentMain from "./Components/PaymentMain/PaymentMain";

import MainSection from "./Components/MainSection/MainSection";
import PartyMain from "./Components/PartyMain/PartyMain";
import SearchItems from "./Components/PartyMain/SearchItems";
import Pagenation from "./Components/PartyMain/Pagination/Pagenation";
import MainList from "./Components/MainListItem/MainList";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import RenteePickUp from "./Components/RenteePickupMain/RenteePickUp.jsx/RenteePickUp";
import RenteeChatMain from "./Components/RenteeChatMain/RenteeChatMain";
import RenteeReturn from "./Components/RenteeReturnMain/RenteeReturn";

import RegisterMain from "./Components/RegisterMain/RegisterMain";
import ProfileMain from "./Components/ProfileMain/ProfileMain";
import ListCarMain from "./Components/ListCartMain/ListCarMain";
import RenteedMain from "./Components/RenteedItemMain/RenteedMain";
import RentedItemChatMain from "./Components/RentedItemChat/RentedItemChatMain";
import RenteedTimeMain from "./Components/RenteedTimeMain/RenteedTimeMain";
import SignIn from "./Components/RegisterMain/Register/SignIn";
import Forgot from "./Components/RegisterMain/Register/forgot";
import Reset from "./Components/RegisterMain/Register/reset";
import AdminCategory from "./Components/Admin/AdminCategory";
import io from "socket.io-client";
import jwt from "jwt-decode";
import ChatProvider from "./Context/ChatProvider";
import Chat from "./Components/chat/chat";
import AdminSignIn from "./Components/RegisterMain/Register/AdminSignLogin";
import TermsCondition from "./Components/TermsCondition/TermsCondition";
import PrivacyPolicy from "./Components/PrivacyPolicy/PrivacyPolicy";
import Detail from "./Components/Detail/detail";
import Saved from "./Components/PartyMain/Party/Saved";
// const socket = io("http://localhost:6002", {
//   query: { id: "123" },
//   transports: ["websocket"],
// });
const PrivateRoutes = () => {
  const [token, setAdminToken] = useState(sessionStorage?.getItem("token"));

  return token !== null ? <Outlet /> : <Navigate to="/LoginPage" />;
};
const Redirect = () => {
  const [token, setAdminToken] = useState(sessionStorage?.getItem("token"));

  return token !== null ? <Navigate to="/RenteeMain" /> : <Outlet />;
};
const AdminPrivateRoutes = () => {
  const [token, setAdminToken] = useState(
    sessionStorage?.getItem("adminToken")
  );

  return token !== null ? <Outlet /> : <Navigate to="/admin/login" />;
};
const AdminRedirect = () => {
  const [token, setAdminToken] = useState(
    sessionStorage?.getItem("adminToken")
  );

  return token !== null ? <Navigate to="/admin/Home" /> : <Outlet />;
};
function App() {
  return (
    <>
      <BrowserRouter>
        <ChatProvider>
          <Routes>
            <Route path="/chatt" element={<Chat />} />
            <Route element={<PrivateRoutes />}>
              <Route exact path="/" element={<MainSection />} />
              <Route path="/chatt" element={<Chat />} />
              <Route path="/RenteeMain" element={<MainSection />} />
              <Route path="/PartyMain" element={<PartyMain />} />
              <Route path="/search" element={<SearchItems />} />
              <Route path="/saved" element={<Saved />} />
              <Route path="/item/:id" element={<Detail />} />
              <Route path="/PartyMain/:id" element={<MainSection />} />
              <Route path="/Chat" element={<ChatHome />} />
              <Route path="/PickUp" element={<PickupMain />} />
              <Route path="/ReturnLocation" element={<ReturnLocMain />} />
              <Route path="/Confirmation" element={<Confirmation />} />
              <Route path="/Rating" element={<RatingMain />} />
              <Route path="/Reviews" element={<ReviewMain />} />
              <Route path="/Payment" element={<PaymentMain />} />
              <Route path="/RenteeMain" element={<MainSection />} />
              <Route path="/Pages" element={<Pagenation />} />
              <Route path="/MainList" element={<MainList />} />
              <Route path="/RenteePickUp" element={<RenteePickUp />} />
              <Route path="/RenteeChat" element={<RenteeChatMain />} />
              <Route path="/RenteeReturn" element={<RenteeReturn />} />
              <Route path="/Profile" element={<ProfileMain />} />
              <Route path="/AddListItem" element={<ListCarMain />} />
              <Route path="/RenteedItems" element={<RenteedMain />} />
              <Route path="/termscondition" element={<TermsCondition />} />
              <Route path="/privacypolicy" element={<PrivacyPolicy />} />
              <Route path="/RentedChats" element={<RentedItemChatMain />} />
              <Route path="/RenteedPickup" element={<RenteedTimeMain />} />{" "}
            </Route>
            <Route path="/Registration" element={<RegisterMain />} />
            <Route path="/forgot" element={<Forgot />} />
            <Route path="/reset" element={<Reset />} />
            <Route element={<Redirect />}>
              <Route path="/LoginPage" element={<SignIn />} />
            </Route>
            <Route element={<AdminPrivateRoutes />}>
              <Route path="/admin/Home" element={<AdminCategory />} />
            </Route>
            <Route element={<AdminRedirect />}>
              <Route path="/admin/login" element={<AdminSignIn />} />
            </Route>
          </Routes>
        </ChatProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
