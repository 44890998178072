import React from 'react'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import RenteeChat from './RenteeChat/RenteeChat'

function RenteeChatMain() {
  return (
   <>
   <Header/>
   <RenteeChat/>
   <Footer/>
   </>
  )
}

export default RenteeChatMain
