import React, { useState, useEffect } from "react";
import "./RenteeCard.css";
import Select from "react-select";
import RenteeCardsArr from "./RenteeCardsArr";

import BtmImg from "../../Images/BtmImg.png";
import { Link, useNavigate } from "react-router-dom";
import PickUpRentee from "../../RenteePickupMain/RenteePickup/PickUpRentee";

export default function RenteeCard(props) {
  const [firstSection, setFirstSection] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [type, setType] = useState([]);
  const [state, setState] = useState();
  // console.log(state, "12");
  let navigate = useNavigate();
  useEffect(() => {
    let rand = [];
    props?.type.map((da) => {
      let df = da;
      df = { ...df, value: da.name, label: da.name };
      rand.push(df);
    });
    setType(rand);
  }, [props]);

  return (
    <>
      <div className="CardMain">
        <div className="CardName"> Categories</div>

        <div className="CardContent">
          <div className="MainCards">
            {type && (
              <Select
                className="sk"
                options={type}
                onChange={(e) => {
                  setState(e._id);
                  console.log(e._id);
                }}
              />
            )}
          </div>
          <div>
            <PickUpRentee state={state} />
          </div>
        </div>
      </div>
    </>
  );
}
