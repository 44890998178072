import React, { useState, useEffect } from "react";
import "./chat.css";
import MyChats from "./MyChats";
import { Box } from "@chakra-ui/layout";
import { ChatState } from "../../Context/ChatProvider";
import Chatbox from "../chat/Chatbox";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
const Chat = () => {
  const [fetchAgain, setFetchAgain] = useState(false);
  const { user } = ChatState();
  useEffect(() => {
    const element = document.getElementById("root");
    console.log(element.offsetWidth);
  }, []);

  return (
    <div>
      <Header />
      <div style={{ width: "100%" }}>
        <Box
          display="flex"
          justifyContent="space-between"
          w="100%"
          h="91.5vh"
          p="10px"
        >
          {user && <MyChats fetchAgain={fetchAgain} />}
          {user && (
            <Chatbox fetchAgain={fetchAgain} setFetchAgain={setFetchAgain} />
          )}
        </Box>
      </div>
      <Footer />
    </div>
  );
};

export default Chat;
