import React from 'react'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import PickUpSection from '../PickUpSection/PickUpSection'


function PickupMain() {
  return (
   <>
   <Header />
   <PickUpSection />
   <Footer />
   
   </>
  )
}

export default PickupMain
