import React, { useState, useEffect } from "react";
import { Button, Modal } from "antd";
import { serverInstance } from "../../axiosInsatnce";
import "./catagory.css";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
import { Link, useNavigate } from "react-router-dom";
import { FaLongArrowAltLeft, FaLongArrowAltRight } from "react-icons/fa";
const AdminCategory = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({ name: "", ima: "" });
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState("Content of the modal");
  const [type, setType] = useState([]);
  const [edit, setEdit] = useState("");
  useEffect(() => {
    serverInstance
      .get("/category/all")
      .then((res) => {
        console.log(res);
        setType(res.data.data);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  }, []);
  const [itemOffset, setItemOffset] = useState(0);
  let itemsP = 10;
  const endOffset = itemOffset + itemsP;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = type.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(type.length / itemsP);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsP) % type.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    setModalText("The modal will be closed after two seconds");
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };
  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };
  const handleSubmit = async () => {
    if (edit == "edit") {
      try {
        const dat = await serverInstance.put("/category/update", {
          name: state.name,
          _id: state.id,
        });
        console.log(dat, "dataaa");
        const index = type.findIndex((item) => item._id === dat.data.data._id);
        let updateTpye = [...type];
        updateTpye[index] = dat.data.data;
        console.log(updateTpye, "dat.data.data");
        setType(updateTpye);
        Swal.fire("Updated successfully", "", "success");
      } catch (err) {
        console.log(err);
        Swal.fire(
          err.response.data.message || "Something went wrong!",
          "",
          "error"
        );
      }
    } else {
      try {
        const dat = await serverInstance.post("/category/create", {
          name: state.name,
        });
        console.log(dat, "dataaa");
        setType(dat.data.data);
        Swal.fire("Added successfully", "", "success");
      } catch (err) {
        console.log(err);
        Swal.fire(
          err.response.data.message || "Something went wrong!",
          "",
          "error"
        );
      }
    }
  };
  const handleDelete = async (id) => {
    try {
      const dat = await serverInstance.delete(`/category/delete/${id}`, {
        name: state.name,
        _id: state.id,
      });
      console.log(dat, "dataaa");

      setType(dat.data.data);
      Swal.fire("Deleted successfully", "", "success");
    } catch (err) {
      console.log(err);
      Swal.fire(
        err.response.data.message || "Something went wrong!",
        "",
        "error"
      );
    }
  };
  return (
    <div>
      {" "}
      <div className="SectionMain">
        <div className="Rentee-Heading">
          <div className="RenteeHead">Categories added by Admin</div>
        </div>
        <div className="d-flex justify-content-between ps-2 pe-2">
          <Link to={"/RenteeMain"}>
            <div className="bhh">Return to client side</div>
          </Link>

          <div>
            <div
              className="bhh"
              onClick={() => {
                sessionStorage.removeItem("adminToken");
                navigate("/admin/loginPage");
              }}
            >
              Logout from Admin
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-5 mb-5">
          <div
            className="AddS"
            onClick={() => {
              showModal();
              setEdit("");
            }}
          >
            {" "}
            Add New Category
          </div>
        </div>
        <div className="d-flex flex-column align-items-center">
          {" "}
          {currentItems?.map((cata) => {
            return (
              <div className="d-flex mt-1 mb-1 ">
                <div className="catName">{cata.name}</div>
                <div
                  className="edtdele"
                  onClick={() => {
                    showModal();
                    setEdit("edit");
                    setState({ ...state, name: cata.name, id: cata._id });
                  }}
                >
                  Edit
                </div>
                <div
                  className="edtdele"
                  onClick={() => {
                    handleDelete(cata._id);
                  }}
                >
                  Delete
                </div>
              </div>
            );
          })}
          <div></div>
        </div>
        <div className="d-flex justify-content-center mt-4 mb-5">
          <ReactPaginate
            previousLabel={
              <FaLongArrowAltLeft size={35} style={{ marginTop: "-25px" }} color={"#cc5500"} />
            }
            nextLabel={
              <FaLongArrowAltRight size={35} style={{ marginTop: "-25px" }} color={"#cc5500"} />
            }
            pageCount={pageCount}
            pageRangeDisplayed={2}
            marginPagesDisplayed={1}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link"}
            nextLinkClassName={"pagination__link"}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
          />
        </div>
      </div>
      {/* <Button type="primary" onClick={showModal}>
        Open Modal with async logic
      </Button> */}
      <Modal
        title={edit == "edit" ? "Edit Category Name" : "Add Category Name"}
        open={open}
        onOk={handleOk}
        footer={false}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <div className="d-flex flex-column">
          <label htmlFor="">Name</label>
          <input
            type="text"
            placeholder="Enter Category Name"
            className="dropDownInputBa"
            value={state.name}
            onChange={(e) => {
              setState({ ...state, name: e.target.value });
            }}
          />
        </div>
        {/* <div className="d-flex flex-column">
          <label htmlFor="">image</label>
          <input
            type="file"
            onChange={(e) => {
              console.log(e.target.files[0]);
              setState({ ...state, ima: e.target.files[0] });
            }}
          />
        </div> */}
        <div>
          <div className="AddS mt-3" onClick={handleSubmit}>
            {edit == "edit" ? "Edit" : "Submit"}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AdminCategory;
