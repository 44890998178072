import React from 'react'
import Confirm from '../Confirm/Confirm'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'

export default function Confirmation() {
  return (
   <>
   <Header/>
   <Confirm/>
   <Footer/>
   </>
  )
}
