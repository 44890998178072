import React from "react";
import SwiperMain from "../Swiper/SwiperMain";
import Swiper from "../Swiper/SwiperMain";
import "./RenteeMain.css";

export default function RenteeMain(props) {
  return (
    <div>
      <div className="FirstSectionMain">
        <div className="Rentee-Heading">
          <div className="RenteeHead">Some items frequently rented</div>
        </div>

        {/* <div className='SliderMain'> */}
        <SwiperMain cards={props.cards} />
        {/* </div> */}
      </div>
    </div>
  );
}
