import React, { useState } from "react";
import "./Register.css";
import LoginMain from "../../Images/newbg.jpg";
import { FiFacebook } from "react-icons/fi";

import { CiInstagram } from "react-icons/ci";
import { CiTwitter } from "react-icons/ci";
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";
import { useNavigate, Link } from "react-router-dom";
import { serverInstance } from "../../../axiosInsatnce";
import Swal from "sweetalert2";
import logo from "../../Images/logo.png";
const AdminSignIn = () => {
  let navigate = useNavigate();
  const [state, setState] = useState({ email: "", password: "" });
  const [show, setShow] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (state.email == "") return Swal.fire("Enter a valid email", "", "error");
    if (state.password == "")
      return Swal.fire("Enter a valid password", "", "error");

    try {
      const data = await serverInstance.post("/user/admin/login", state);
      console.log(data, "dataaa");
      sessionStorage.setItem("adminToken", data.data.token);
      navigate("/admin/Home", { replace: true });
      Swal.fire("Admin login successfully", "", "success");
    } catch (err) {
      console.log(err);
      Swal.fire(
        err.response.data.message || "Something went wrong!",
        "",
        "error"
      );
    }
  };
  return (
    <div className="RegisterMain">
      <>
        <div className="ImgPortion">
          <img className="Img-back" src={LoginMain} />
          <div className="LeftHead">
            <div className="Login-Head">
              {" "}
              <img src={logo} alt="" width={"250px"} />
            </div>
            <div className="Member">Welcome Back</div>
            <div className="RegisterContent">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore,
              voluptate error. Lorem ipsum dolor sit amet consectetur
              adipisicing elit. Dolore, voluptate error.
            </div>

            <div className="PortionIcons">
              <div className="borderIcon">
                <FiFacebook className="imageIcons" />
              </div>
              <div className="borderIcon">
                <CiInstagram className="imageIcons" />
              </div>
              <div className="borderIcon">
                <CiTwitter className="imageIcons" />
              </div>
            </div>
          </div>
        </div>

        <div className="TextPortion">
          <div className="RightPortion">
            <div className="TextHead"> Admin Login</div>
            <div className="textContent">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere,
              veritatis quae.
            </div>
          </div>

          <div className="InputPortion">
            <div className="BarsPortion">
              <input
                className="InputBars"
                placeholder="Email"
                value={state.email}
                onChange={(e) => {
                  setState({ ...state, email: e.target.value });
                }}
              />
              <div className="InputBars">
                <input
                  className="pass-Input"
                  type={show == true ? "text" : "password"}
                  placeholder="Password"
                  value={state.password}
                  onChange={(e) => {
                    setState({ ...state, password: e.target.value });
                  }}
                />
                <i className="pass-Icon">
                  {show == true ? (
                    <span
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      {" "}
                      <AiFillEyeInvisible />
                    </span>
                  ) : (
                    <span
                      onClick={() => {
                        setShow(true);
                      }}
                    >
                      <AiFillEye />
                    </span>
                  )}
                </i>
              </div>
            </div>
          </div>

          <div className="registerBtn">
            <button className="TextBtn" onClick={handleSubmit}>
              Login
            </button>{" "}
          </div>
        </div>
      </>
    </div>
  );
};

export default AdminSignIn;
