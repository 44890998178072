import React from 'react'
import Footer from '../../Footer/Footer'
import Header from '../../Header/Header'
import Party from '../Party/Party'

function Pagenation() {
  return (
    <>
    <Header/>
    <Party/>
    <Footer/>

    </>
  )
}

export default Pagenation