import React, { useState, useEffect } from "react";
import "../../PickUpSection/PickUpSection.css";
import Calendar from "react-calendar";
// import { Timeit } from 'react-timeit'
import { IoIosArrowBack } from "react-icons/io";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { serverInstance } from "../../../axiosInsatnce";
import { ChatState } from "../../../Context/ChatProvider";
import Swal from "sweetalert2";
function PickUpRentee(props) {
  let navigate = useNavigate();
  const { user } = ChatState();
  const location = useLocation();
  const [disab, setDisab] = useState(true);
  const [state, setState] = useState({ id: props?.state, date: "" });
  const [cards, setCards] = useState([]);
  useEffect(() => {
    console.log(location, "location");
    setState({ ...state, id: props?.state });
  }, [props?.state]);

  return (
    <>
      {/* <div className="Pickup-Main">
        <Link to={"/RenteeMain"}>
          <div className="Return-btn"> */}
      {/* <IoIosArrowBack className='Return-icon' /> */}
      {/* <div className="MainBtn">Return</div>
          </div>
        </Link>
        <div className="MainShade-box">
          <div className="Heading">Pickup date </div>
        </div>
      </div> */}

      {/* <div className="Heading-Pickup">Pickup / Delivery</div> */}
      {/* <div className="Top-card"> */}
      {/* <div className="border-content">
          <div className="Main-content">
            <div
              style={{ fontWeight: "bold", color: "orange", fontSize: "25px" }}
            >
              Disclaimer
            </div>
            Rentyoyo is only providing a platform to help rentees meet and
            choose renters for their rental needs and Rentyoyo is not legally
            responsible for any aspect of the transaction between rentees and
            renters (especially in all of the parties’ interactions outside the
            platform).
          </div>
        </div> */}
      {/* <div className="border-content">
          <div className="SecBox-content">Select One</div>
          <div class="form-check">
            <div class="styled-input-single">
              <input type="radio" name="fieldset-3" id="radio3-example-three" />
              <label class="form-check-label" for="radio3-example-three">
                Delivery
              </label>
            </div>
          </div>
          <div class="Secform-check">
            <div class="styled-input-single">
              <input type="radio" name="fieldset-3" id="radio3-example-three" />
              <label class="form-check-label" for="radio3-example-three">
                Pick up
              </label>
            </div>
          </div>
        </div> */}
      {/* </div> */}

      <div className="Sec-card">
        <div className="Secborder-content">
          <div className="calendar-Head">Pick a date and Time</div>
          <Calendar
            className="react-calendar"
            onChange={(e) => {
              console.log(e.toISOString().slice(0, 10));
              setState({ ...state, date: e.toISOString().slice(0, 10) });
              serverInstance
                .get(
                  `/item/all/${state.id}/${e.toISOString().slice(0, 10)}/${
                    user._id
                  }`
                )
                .then((res) => {
                  console.log(res);
                  setCards(res.data.data);
                  setDisab(false);
                })
                .catch((err) => {
                  console.log(err, "err");
                });
            }}
          />
        </div>
        {/* <div className="border-content">
          <div className="SecBox-content">Select One</div>
          <div className="Timer-Main">
            <div className="topHours">
              <div className="Hours" onClick={hoursClicked}>
                {hoursTxt}
              </div>
              {showHours && (
                <div className="cardHours">
                  {data.map((item) => (
                    <div
                      className="hoursTxt"
                      onClick={() => hourSelectClicked(item.hours)}
                    >
                      {item.hours}
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="Timer-Coln">:</div>

            <div className="topHours">
              <div className="Hours" onClick={minutesClicked}>
                {minutesTxt}

                {showMinutes && (
                  <div className="SecCardHours">
                    {minutes.map((item) => (
                      <div
                        className="hoursTxt"
                        onClick={() => minutesSelectClicked(item.minutes)}
                      >
                        {item.minutes}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div className="topHours">
              <div className="D-N" onClick={amPmClicked}>
                {amPmTxt}
              </div>
              {showAmPm && (
                <div className="AmPMCardHours">
                  {amPm.map((item) => (
                    <div
                      className="hoursTxt"
                      onClick={() => amPmSelectClicked(item.amPm)}
                    >
                      {item.amPm}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div> */}
      </div>
      <div className="Btn-parent">
        <div className="ThrdNext-Btn">
          {/* <Link to={{ pathname: "/PartyMain", state: { cards: cards } }}> */}
          <button
            disabled={disab}
            className="SecNxt-Btn"
            style={disab ? { opacity: 0.5 } : {}}
            onClick={() => {
              if (state.date == "") {
                Swal.fire("Select a date", "", "error");
              } else {
                navigate("/PartyMain", { state: { cards: cards, d: state } });
              }
            }}
          >
            Next
          </button>
          {/* </Link> */}
        </div>
      </div>
    </>
  );
}

export default PickUpRentee;
