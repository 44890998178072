import React from 'react'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import ListCart from './ListCart/ListCart'

function ListCarMain() {
  return (
   <>
   <Header/>
   <ListCart/>
   <Footer/>
   </>
  )
}

export default ListCarMain
