import React from "react";
import "./ListCart.css";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { useRef } from "react";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { serverInstance } from "../../../axiosInsatnce";
import { ChatState } from "../../../Context/ChatProvider";
import Swal from "sweetalert2";
import { MdDelete } from "react-icons/md";
function ListCart() {
  const inputRef = useRef(null);
  const { user } = ChatState();
  const [state, setState] = useState({
    name: "",
    category: "",
    description: "",
    image: [],
    dailyPrice: "",
    availableDays: "",
    startDate: "",
    deliveryAvailable: "",
    user: user?._id,
  });
  const [type, setType] = useState([]);
  const [iMage, setIMage] = useState(null);
  const [imagesPreview, setImagesPreview] = useState([]);

  useEffect(() => {
    serverInstance
      .get("/category/all")
      .then((res) => {
        console.log(res);
        setState({ ...state, category: res.data.data[0]?._id });
        setType(res.data.data);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  }, []);

  const handleClick = (files) => {
    // 👇️ open file input box on click of other element
    inputRef.current.click();
  };
  const handleFileChange = (event) => {
    const fileObjs = event.target.files;

    if (!fileObjs || fileObjs.length === 0) {
      return;
    }

    const imageFiles = Array.from(fileObjs);

    setState({ ...state, image: imageFiles });

    // Display image previews
    const previews = imageFiles.map((file) => URL.createObjectURL(file));
    setImagesPreview(previews);
  };
  // const handleFileChange = (event) => {
  //   const fileObj = event.target.files && event.target.files[0];
  //   if (!fileObj) {
  //     return;
  //   }

  //   console.log("fileObj is", fileObj);

  //   // 👇️ reset file input
  //   event.target.value = null;

  //   // 👇️ is now empty
  //   console.log(event.target.files);

  //   // 👇️ can still access file object here
  //   console.log(fileObj);
  //   console.log(fileObj.name);
  // };
  const handleConfirm = async () => {
    const allFieldsNotEmpty = Object.values(state).every((value) => {
      // Check for non-empty strings, arrays, and truthy values
      return (
        (typeof value === "string" && value.trim() !== "") ||
        (Array.isArray(value) && value.length > 0) ||
        !!value
      );
    });
    if (allFieldsNotEmpty) {
      let data = new FormData();
      data.append("name", state.name);
      state.image.forEach((image, index) => {
        data.append(`images`, image);
      });
      data.append("category", state.category);
      data.append("description", state.description);
      data.append("dailyPrice", state.dailyPrice);
      data.append("availableDays", state.availableDays);
      data.append("startDate", state.startDate);
      data.append("deliveryAvailable", state.deliveryAvailable);
      data.append("user", state.user);
      try {
        const dat = await serverInstance.post("/item/create", data);
        console.log(dat, "dataaa");

        Swal.fire("Added successfully", "", "success");
      } catch (err) {
        console.log(err);
        Swal.fire(
          err.response.data.message || "Something went wrong!",
          "",
          "error"
        );
      }
    } else {
      Swal.fire("Fill all the fields", "", "error");
    }
  };
  const handleDeleteImage = (index) => {
    // Create a copy of the images array
    const updatedImages = [...state.image];

    // Remove the image at the specified index
    updatedImages.splice(index, 1);

    // Update state and image previews
    setState({ ...state, image: updatedImages });
    setImagesPreview(imagesPreview.filter((_, i) => i !== index));
  };
  const options = ["Gift", "Party", "Wedding"];
  return (
    <>
      <div className="boxProfile">
        <div className="MainShade-box">
          <div className="Heading">Profile</div>
        </div>

        <div className="MainListingsCards">
          <div className="listCartBoxHeading">Items 1</div>
          <div className="mainListCart">
            <div className="mainDropDownSec">
              <div className="SecDropDown">
                <div className="heading-Cart">Name:</div>
                <div className="heading-Cart">Select category:</div>
                <div className="heading-Cart">Describe items:</div>
              </div>
              <div className="mainDropDownSec">
                <div className="SecHeadingsDropDown">
                  <div className="heading-Cart">Upload pictures:</div>
                  <div className="heading-Cart">
                    Daily rental price in dollars:
                  </div>
                  <div className="heading-Cart">
                    Number of days item is available for rent:
                  </div>
                  <div className="heading-Cart">Rental start date:</div>
                  <div className="heading-Cart">Delivery available?</div>
                </div>
              </div>
            </div>

            <div className="ScndDropDown">
              <div>
                <input
                  type="text"
                  className="dropDownInputBar"
                  placeholder="Enter name of item"
                  value={state.name}
                  onChange={(e) => {
                    setState({ ...state, name: e.target.value });
                  }}
                />
              </div>
              {type && (
                <select
                  className="selec"
                  // value={type.filter((d) => state.category == d._id)[0]?.name}
                  onChange={(e) => {
                    setState({ ...state, category: e.target.value });
                  }}
                >
                  {type.map((d) => {
                    return <option value={d._id}>{d.name}</option>;
                  })}
                </select>
              )}

              <div>
                <textarea
                  rows="4"
                  cols="50"
                  className="DescribeInput"
                  placeholder="Type Here"
                  value={state.description}
                  onChange={(e) => {
                    setState({ ...state, description: e.target.value });
                  }}
                />
              </div>
              <div className="d-flex flxColumn ">
                <div className="upload-btn-wrapper uploadPictures">
                  <button className="picBtnTxt">Upload pictures</button>{" "}
                  <input
                    // style={{ display: "none" }}
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={handleFileChange}
                  />
                </div>
                <div className="d-flex gap-5 custom-scroll">
                  {imagesPreview.map((preview, index) => (
                    <div
                      key={index}
                      className="image-preview"
                      style={{ position: "relative" }}
                    >
                      <img
                        src={preview}
                        alt={`Preview ${index}`}
                        className="imge"
                      />
                      <button
                        style={{
                          position: "absolute",
                          right: "-4px",
                          top: "0px",
                          color: "red",
                          background: "#272424",
                          padding: "2px",
                          borderRadius: "17px",
                          width: "30px",
                          height: "30px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() => handleDeleteImage(index)}
                      >
                        <MdDelete />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
              <input
                className="dropDownInputBar"
                placeholder="Enter price in dollars"
                type="number"
                value={state.dailyPrice}
                onChange={(e) => {
                  setState({ ...state, dailyPrice: e.target.value });
                }}
              />
              <div>
                {" "}
                <input
                  className="dropDownInputBar"
                  placeholder="Enter no of days "
                  value={state.availableDays}
                  type={"number"}
                  onChange={(e) => {
                    setState({ ...state, availableDays: e.target.value });
                  }}
                />
              </div>
              <div>
                {" "}
                <input
                  className="dropDownInputBar"
                  type={"date"}
                  value={state.startDate}
                  onChange={(e) => {
                    setState({ ...state, startDate: e.target.value });
                  }}
                />
              </div>
              <div>
                <div className="inputsCartSec">
                  <div className="style-Input">
                    <input
                      className="New-color"
                      type="radio"
                      name="fieldset-3"
                      id="radio3-example-three"
                      value={state.deliveryAvailable}
                      onChange={(e) => {
                        setState({ ...state, deliveryAvailable: true });
                      }}
                    />
                    <label class="form-check-label" for="radio3-example-three">
                      Yes
                    </label>
                  </div>
                  <div className="style-SecInput">
                    <input
                      className="New-color"
                      type="radio"
                      name="fieldset-3"
                      id="radio3-example-three"
                      value={state.deliveryAvailable}
                      onChange={(e) => {
                        setState({ ...state, deliveryAvailable: false });
                      }}
                    />
                    <label class="form-check-label" for="radio3-example-three">
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* MAIN-SECOND-CARD */}
      </div>
      <div className="Btn-parent">
        <div className="ThrdNext-Btn">
          {/* <Link to={"/RenteedItems"}> */}
          <button onClick={handleConfirm} className="SecNxt-Btn">
            Confirmed
          </button>
          {/* </Link> */}
        </div>
      </div>
    </>
  );
}

export default ListCart;
