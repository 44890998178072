import React from "react";
import "./SwiperMain.css";
import GiftImg from "../../Images/GiftImg.png";
import "swiper/css/bundle";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { FaLongArrowAltLeft, FaLongArrowAltRight } from "react-icons/fa";
import { useState } from "react";
import Slider from "react-slick";
import { Swiper, SwiperSlide } from "swiper/react";

import { EffectCoverflow, Pagination } from "swiper";
import { BASE_URL } from "../../../axiosInsatnce";

const images = [GiftImg, GiftImg, GiftImg];
function SwiperMain({ cards }) {
  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow next" onClick={onClick}>
        <FaLongArrowAltRight size={75} color={"#cc5500"} />
      </div>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow prev" onClick={onClick}>
        <FaLongArrowAltLeft size={75} color={"#cc5500"} />
      </div>
    );
  };

  const [imgIndex, setImgIndex] = useState(0);

  const settings = {
    infinite: true,
    lazyLoad: true,
    speed: 300,
    slidesToShow: 1,
    centerMode: false,
    centerPadding: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setImgIndex(next),
  };

  return (
    <div className="App">
      <Slider {...settings}>
        {cards.map((img, idx) => (
          <div className={idx === imgIndex ? "slide activeSlide" : "slide"}>
            {img?.image && (
              <img
                src={BASE_URL + "/" + img?.image[0]}
                alt={idx}
                className="topImage"
              />
            )}
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default SwiperMain;
