import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Party.css";
import { FaRegUserCircle } from "react-icons/fa";
import PartyArr from "./PartyArry";
import { BASE_URL, serverInstance } from "../../../axiosInsatnce";
import { ChatState } from "../../../Context/ChatProvider";
import ReactPaginate from "react-paginate";
// import { LuShoppingCart } from "react-icons/lu";
import { GiShoppingCart } from "react-icons/gi";
import { TbListDetails } from "react-icons/tb";
import moment from "moment";
import { FaLongArrowAltLeft, FaLongArrowAltRight } from "react-icons/fa";
import Swal from "sweetalert2";
function Party(props) {
  const navigate = useNavigate();
  const { user } = ChatState();
  const [card, setcard] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  let itemsP = 10;
  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsP;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = card.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(card.length / itemsP);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsP) % card.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };
  useEffect(() => {
    setcard(props.cards);
  }, []);
  const Saved = (item) => {
    serverInstance
      .post("/item/saved", { user: user?._id, item: item })
      .then((res) => {
        console.log(res);
        Swal.fire("Saved as favorite", "", "success");
      })
      .catch((err) => {
        console.log(err, "err");
        Swal.fire(err?.response?.data?.message, "", "error");
      });
  };
  return (
    <>
      <div className="PartyMain">
        <div className="Return-btn">
          <Link to={"/RenteeMain"}>
            <div className="MainBtn">Return</div>
          </Link>
        </div>
        <div className="MainShade-box">
          <div className="Heading" style={{ color: "white" }}>
            Products for {moment(props.hede.date).format("MMMM Do YYYY")}
          </div>
        </div>

        {card.length == 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            There is no item for this
          </div>
        ) : (
          <div className="PartyCards">
            {currentItems?.map((item, arr) => (
              <div className="FirstPartyCard">
                <div className="ImgPrnt">
                  <div className="StyleCheckBox">
                    <div className="clrCheckBox"></div>
                  </div>

                  <div className="ShadeImg">
                    <div>
                      <img
                        className="SecPartyMainImg"
                        src={BASE_URL + "/" + item.image[0]}
                      />
                    </div>
                  </div>
                </div>

                <div className="Content">
                  <div
                    onClick={() => Saved(item?._id)}
                    className="item_save_button"
                  >
                    Save
                    <div>
                      <GiShoppingCart />
                    </div>
                  </div>
                  <div
                    className="item_save_button"
                    onClick={() => {
                      navigate(`/item/${item?._id}`, {
                        replace: true,
                        state: { data: item },
                      });
                    }}
                  >
                    Detail
                    <div>
                      <TbListDetails />
                    </div>
                  </div>
                  <div>
                    <div className="ContentName">{item.name}</div>
                    <div className="ContentDesc">{item.description}</div>
                  </div>
                  <div className="ContentPrice">
                    Daily rental rate:${item.dailyPrice}
                  </div>
                  <div className="SenderDetail">
                    <div>
                      <FaRegUserCircle className="ImgChat" size="30" />
                    </div>
                    <div>{item.user.userName}</div>
                  </div>
                </div>
              </div>
            ))}
            {/* </div>
            );
          })} */}
            <div className="d-flex justify-content-center">
              <ReactPaginate
                // previousLabel={"←"}
                previousLabel={
                  <FaLongArrowAltLeft
                    size={55}
                    style={{ marginTop: "-45px" }}
                    color={"#cc5500"}
                  />
                }
                nextLabel={
                  <FaLongArrowAltRight
                    size={55}
                    style={{ marginTop: "-45px" }}
                    color={"#cc5500"}
                  />
                }
                pageCount={pageCount}
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                previousLinkClassName={"pagination__link"}
                nextLinkClassName={"pagination__link"}
                disabledClassName={"pagination__link--disabled"}
                activeClassName={"pagination__link--active"}
              />
            </div>
            {/* <div className="MainPages">
            <div className="borderPage">
              <Link to={"/Pages"}>
                <div className="SizePage">1</div>
              </Link>
            </div>

            <div className="SecborderPage">
              <Link to={"/Pages"}>
                <div className="SecSizePage">2</div>
              </Link>
            </div>

            <div className="SecborderPage">
              <Link to={"/Pages"}>
                <div className="SecSizePage">3</div>
              </Link>
            </div>

            <div className="SecborderPage">
              <div className="SecSizePage">4</div>
            </div>

            <div>
              <div className="dots"></div>
            </div>
            <div>
              <div className="dots"></div>
            </div>
            <div>
              <div className="dots"></div>
            </div>

            <div className="SecborderPage">
              <div className="SecSizePage">10</div>
            </div>
          </div> */}
          </div>
        )}
      </div>
      {/* <Link to={"/RentedChats"}>
        <div className="Btn-parent">
          <div className="ThrdNext-Btn">
            <button className="SecNxt-Btn">Next</button>
          </div>
        </div>
      </Link> */}
    </>
  );
}

export default Party;
