import React, { useState } from "react";
import "./Register.css";
import LoginMain from "../../Images/newbg.jpg";
import { FiFacebook } from "react-icons/fi";

import { CiInstagram } from "react-icons/ci";
import { CiTwitter } from "react-icons/ci";
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";
import { useNavigate, Link } from "react-router-dom";
import { serverInstance } from "../../../axiosInsatnce";
import Swal from "sweetalert2";
import logo from "../../Images/logo.png";
const Forgot = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({ email: "" });
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (state.email == "") return Swal.fire("Enter a valid email", "", "error");
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/.test(state.email)) {
      try {
        const data = await serverInstance.put("/user/forgot", state);
        console.log(data, "dataaa");

        Swal.fire("email sent successfully", "", "success");
        navigate(
          "/reset",
          {
            state: {
              email: state.email,
            },
          },
          { replace: true }
        );
      } catch (err) {
        console.log(err);
        Swal.fire(err.response.data.message, "", "error");
      }
    } else return Swal.fire("Enter a valid Email", "", "error");
  };
  return (
    <div className="RegisterMain">
      <>
        <div className="ImgPortion">
          <img className="Img-back" src={LoginMain} />
          <div className="LeftHead">
            <div className="Login-Head">
              {" "}
              <img src={logo} alt="" width={"250px"} />
            </div>
            {/* <div className="Member">Welcome Back</div>
            <div className="RegisterContent">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore,
              voluptate error. Lorem ipsum dolor sit amet consectetur
              adipisicing elit. Dolore, voluptate error.
            </div>

            <div className="PortionIcons">
              <div className="borderIcon">
                <FiFacebook className="imageIcons" />
              </div>
              <div className="borderIcon">
                <CiInstagram className="imageIcons" />
              </div>
              <div className="borderIcon">
                <CiTwitter className="imageIcons" />
              </div>
            </div> */}
          </div>
        </div>

        <div className="TextPortion">
          <div className="RightPortion">
            <div className="TextHead">Forgot Password</div>
          </div>

          <div className="InputPortion">
            <div className="BarsPortion">
              <input
                className="InputBars"
                placeholder="Email"
                value={state.email}
                onChange={(e) => {
                  setState({ ...state, email: e.target.value });
                }}
              />
            </div>

            {/* <div className="LinkBtn">
              <div className="LinkBtn-Text">Forgot Password?</div>
            </div> */}
          </div>

          <div className="registerBtn">
            <button className="TextBtn" onClick={handleSubmit}>
              Send Email
            </button>{" "}
          </div>

          <div className="SecregisterBtn">
            <Link to={"/LoginPage"}>
              <button className="SecTextBtn">Login</button>
            </Link>
          </div>
        </div>
      </>
    </div>
  );
};

export default Forgot;
