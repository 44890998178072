import React, { useState } from "react";
import "./Register.css";
import LoginMain from "../../Images/newbg.jpg";
import { FiFacebook } from "react-icons/fi";

import { CiInstagram } from "react-icons/ci";
import { CiTwitter } from "react-icons/ci";
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { serverInstance } from "../../../axiosInsatnce";
import Swal from "sweetalert2";
import logo from "../../Images/logo.png";
const Reset = () => {
  const location = useLocation();
  let navigate = useNavigate();
  const [state, setState] = useState({
    email: location.state.email,
    password: "",
    confirmPassword: "",
    code: "",
  });
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  function validate_password(password) {
    let check =
      /^(?=[^A-Z\n]*[A-Z])(?=[^a-z\n]*[a-z])(?=[^0-9\n]*[0-9])(?=[^#?!@$%^&*\n-]*[#?!@$%^&*-]).{8,}$/;
    if (state.password !== state.confirmPassword) {
      Swal.fire("password and confirm password should be same!", "", "error");
      console.log("Meh, not so much.");
      return false;
    } else {
      if (password.match(check)) {
        console.log("Your password is strong.");

        return true;
      } else {
        Swal.fire("Enter strong passwords", "", "error");
        console.log("Meh, not so much.");
        return false;
      }
    }
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (state.code == "") return Swal.fire("Enter a otp code", "", "error");
    if (state.password == "")
      return Swal.fire("Enter a strong password", "", "error");
    if (validate_password(state.password)) {
      try {
        const data = await serverInstance.put("/user/reset", state);
        console.log(data, "dataaa");

        navigate("/LoginPage", { replace: true });
        Swal.fire("Password changed", "", "success");
      } catch (err) {
        console.log(err);
        Swal.fire(err.response.data.message, "", "error");
      }
    }
  };
  return (
    <div className="RegisterMain">
      <>
        <div className="ImgPortion">
          <img className="Img-back" src={LoginMain} />
          <div className="LeftHead">
            <div className="Login-Head">
              {" "}
              <img src={logo} alt="" width={"250px"} />
            </div>
            {/* <div className="Member">Be a Member</div>
            <div className="RegisterContent">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore,
              voluptate error. Lorem ipsum dolor sit amet consectetur
              adipisicing elit. Dolore, voluptate error.
            </div> */}

            {/* <div className="PortionIcons">
              <div className="borderIcon">
                <FiFacebook className="imageIcons" />
              </div>
              <div className="borderIcon">
                <CiInstagram className="imageIcons" />
              </div>
              <div className="borderIcon">
                <CiTwitter className="imageIcons" />
              </div>
            </div> */}
          </div>
        </div>

        <div className="TextPortion">
          <div className="RightPortion">
            <div className="TextHead">Reset Password</div>
          </div>

          <div className="InputPortion">
            <div className="BarsPortion">
              <input
                className="InputBars"
                placeholder="Code"
                type="number"
                value={state.code}
                onChange={(e) => {
                  setState({ ...state, code: e.target.value });
                }}
              />
              <div className="InputBars">
                <input
                  className="pass-Input"
                  type={show == true ? "text" : "password"}
                  placeholder="Password"
                  value={state.password}
                  onChange={(e) => {
                    setState({ ...state, password: e.target.value });
                  }}
                />
                <i className="pass-Icon">
                  {show == true ? (
                    <span
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      {" "}
                      <AiFillEyeInvisible />
                    </span>
                  ) : (
                    <span
                      onClick={() => {
                        setShow(true);
                      }}
                    >
                      <AiFillEye />
                    </span>
                  )}
                </i>
              </div>
              <div className="InputBars">
                <input
                  className="pass-Input"
                  type={show == true ? "text" : "password"}
                  placeholder="Confirm Password"
                  value={state.confirmPassword}
                  onChange={(e) => {
                    setState({ ...state, confirmPassword: e.target.value });
                  }}
                />
                <i className="pass-Icon">
                  {show1 == true ? (
                    <span
                      onClick={() => {
                        setShow1(false);
                      }}
                    >
                      {" "}
                      <AiFillEyeInvisible />
                    </span>
                  ) : (
                    <span
                      onClick={() => {
                        setShow1(true);
                      }}
                    >
                      <AiFillEye />
                    </span>
                  )}
                </i>
              </div>
            </div>

            {/* <div className="LinkBtn">
              <div className="LinkBtn-Text" onClick={handleSubmit}>
                Reset Password
              </div>
            </div>*/}
          </div>

          <div className="registerBtn">
            {" "}
            <button className="TextBtn" onClick={handleSubmit}>
              Reset Password
            </button>{" "}
          </div>
          <div className="SecregisterBtn">
            <Link to={"/LoginPage"}>
              <button className="SecTextBtn">Login</button>
            </Link>
          </div>
        </div>
      </>
    </div>
  );
};

export default Reset;
