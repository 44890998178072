import React from "react";

import Header from "../Header/Header";
import RenteeCard from "./RenteCardMain/RenteeCard";
import RenteeMain from "./RenteeMain/RenteeMain";
import Footer from "../Footer/Footer";
import { useState, useEffect } from "react";
import { serverInstance } from "../../axiosInsatnce";
import Swal from "sweetalert2";
function MainSection() {
  const [type, setType] = useState([]);
  const [cards, setCards] = useState([]);
  useEffect(() => {
    serverInstance
      .get("/category/all")
      .then((res) => {
        console.log(res);
        setType(res.data.data);
      })
      .catch((err) => {
        console.log(err, "err");
      });
    serverInstance
      .get(`/item/top`)
      .then((res) => {
        console.log(res);
        setCards(res.data.data);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  }, []);
  return (
    <>
      <Header />
      <RenteeMain cards={cards} />
      <RenteeCard type={type} />
      <Footer />
    </>
  );
}

export default MainSection;
