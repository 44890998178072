import React from 'react'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import ReturnRentee from './ReturnRentee/ReturnRentee'

function RenteeReturn() {
  return (
 <>
 <Header/>
 <ReturnRentee/>
 <Footer/>
 </>
  )
}

export default RenteeReturn
