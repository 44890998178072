import React from 'react'
import Register from './Register/Register'


function RegisterMain() {
  return (
    <>
    <Register/>
    </>
  )
}

export default RegisterMain
