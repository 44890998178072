import React from "react";
import "./Footer.css";
import { TfiFacebook } from "react-icons/tfi";
import { FaInstagram, FaTiktok, FaYoutube } from "react-icons/fa";
import { SiTwitter } from "react-icons/si";
import logo from "../Images/logo.png";
import { MdPlace } from "react-icons/md";
import { AiOutlineMail } from "react-icons/ai";
import { AiFillPhone } from "react-icons/ai";
import { Link } from "react-router-dom";

export default function Footer() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      <div style={{ width: "100%", marginTop: "20px" }}>
        <div className="Rentee-Content">
          <div className="RenteeContentHead">
            <div
              style={{ fontWeight: "bold", color: "orange", fontSize: "25px" }}
            >
              Disclaimer
            </div>
            Rentviz is only providing a platform to help rentees meet and choose
            renters for their rental needs and Rentviz is not legally
            responsible for any aspect of the transaction between rentees and
            renters (especially in all of the parties’ interactions outside the
            platform).
          </div>
        </div>
      </div>
      <div className="Main-footer">
        <div className="Footer-Main">
          <div className="Footer-head">
            <img src={logo} alt="" width={"150px"} />
          </div>
          <div>Email:</div>
          <div className="Mail-text">
            {" "}
            <AiOutlineMail />
            info@rentviz.com
          </div>
          <div>Phone Number :</div>
          <div className="Mail-text">
            {" "}
            <AiFillPhone />
            +1 281 407 1887
          </div>
          <div>Address :</div>
          <div className="Mail-text">
            {" "}
            <MdPlace /> 1708 Spring Green Blvd Ste 120, Katy TX 77494
          </div>
          <div className="Icons">
            <a
              href="https://www.facebook.com/profile.php?id=100089907945643"
              target="_blank"
            >
              <div className="logo">
                <TfiFacebook />
              </div>
            </a>
            <a
              href="https://www.instagram.com/rentyoyo_official/"
              target="_blank"
            >
              <div className="logo">
                <FaInstagram />
              </div>
            </a>
            <a href="https://www.tiktok.com/@rentyoyo" target="_blank">
              <div className="logo">
                <FaTiktok />
              </div>
            </a>
            <a
              href=" https://www.youtube.com/channel/UCF6BED5dGPD3WPLSumAjbYg"
              target="_blank"
            >
              <div className="logo">
                <FaYoutube />
              </div>
            </a>
          </div>
        </div>

        <div className="Footer-SecMain">
          <div className="Info-footer">
            <div className="Info-Srvc">Service</div>
            <div className="click">Home</div>
            <div className="click">Categories</div>
            <div className="click">Pickup</div>
          </div>
          <div className="Info-footer">
            <div className="Info-Srvc">Company</div>
            <div className="click">About</div>
            <div className="click">Contact</div>
            <div className="click">Sitemap</div>
            <div className="click">Campaigns</div>
            <div className="click">Blogs</div>
          </div>
          <div className="Info-footer">
            <div className="Info-Srvc">Help Center</div>
            <Link
              to="/termscondition"
              style={{ textDecoration: "none" }}
              onClick={scrollToTop}
            >
              <div className="click"> Terms of Services </div>
            </Link>
            <Link
              to="/privacypolicy"
              style={{ textDecoration: "none" }}
              onClick={scrollToTop}
            >
              <div className="click"> Privacy Policy </div>
            </Link>

            <div className="click">Support</div>
            <div className="click">FAQs</div>
          </div>
        </div>
      </div>
    </>
  );
}
