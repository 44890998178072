import React from 'react'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import RenteedItem from './RenteedItems/RenteedItem'

function RenteedMain() {
  return (
   <>
   <Header/>
   <RenteedItem/>
   <Footer/>
   </>
  )
}

export default RenteedMain
