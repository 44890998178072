import React from 'react'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import Payment from '../Payment/Payment'

function PaymentMain() {
  return (
   <>
   <Header/>
   <Payment/>
   <Footer/>
   </>
  )
}

export default PaymentMain
