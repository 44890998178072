import React, { useEffect, useState } from "react";
import { BASE_URL, serverInstance } from "../../axiosInsatnce";
import VerticalSlider from "./VerticalSlider";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "./detail.css";
import { TbListDetails } from "react-icons/tb";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { ChatState } from "../../Context/ChatProvider";

const Detail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = ChatState();
  const [activeImage, setActiveImage] = useState(null);
  const [item, setItem] = useState(null);
  useEffect(() => {
    setActiveImage(location?.state?.data?.image[0]);
    setItem(location?.state?.data);
  }, []);
  console.log("logger", location?.state?.data);
  return (
    <div>
      {" "}
      <Header />
      <div
        style={{
          width: "100%",
          minHeight: "80vh",
          justifyContent: "center",
          alignItems: "center",
          padding: "40px 15px",
        }}
        className={"d-flex align-items-center justify-content-center"}
      >
        <div
          className={"d-flex align-items-center justify-content-center"}
          style={{ minWidth: "400px", height: "400px" }}
        >
          <VerticalSlider
            pics={item?.image}
            activeImage={activeImage}
            setActiveImage={setActiveImage}
          />
          {activeImage && (
            <img
              src={`${BASE_URL}/${activeImage}`}
              style={{
                height: "388px",
                marginLeft: "40px",
                width: "387px",
              }}
              alt=""
            />
          )}
        </div>
        <div className="bh d-flex flex-column w-50 justify-content-center align-content-center p-5">
          <div
            className="text-left pb-3 text-black text "
            style={{ fontWeight: "800", fontSize: "25px" }}
          >
            Detail of Item
          </div>

          <div className="d-flex flex-wrap gap-2 w-100  justify-content-left align-content-center">
            <div className="hea">
              Name:<span>{item?.name}</span>
            </div>
            <div className="hea">
              Category:<span>{item?.category?.name}</span>
            </div>
            <div className="hea">
              Description:<span>{item?.description}</span>
            </div>
            <div className="hea">
              Daily Price:<span>${item?.dailyPrice}</span>
            </div>
            <div className="hea">
              Available Days:<span>{item?.availableDays}</span>
            </div>
            <div className="hea">
              Start Date:
              <span>{moment(item?.startDate).format("MMMM Do YYYY")}</span>
            </div>
            <div className="hea">
              Delivery Available:{" "}
              <span>
                <input
                  type="checkbox"
                  disabled
                  checked={item?.deliveryAvailable}
                  className="checkbox-input"
                />
              </span>
            </div>
          </div>
          <hr className="mt-3" />
          <div className="d-flex  justify-content-center align-content-center py-3">
            <div className="card-f">
              <div style={{ fontSize: "25px", fontWeight: "600" }}>
                Added by
              </div>
              <div className="card-p">
                <img
                  src={item?.user?.pic}
                  alt=""
                  style={{ borderRadius: "50%" }}
                />
              </div>
              <h3>{item?.user?.userName}</h3>
              <div
                style={{
                  width: "200px",
                  height: "67px",
                  background: "#CC5500",
                  marginTop: "30px",
                  borderRadius: "20px",
                  color: "white",
                  cursor: "pointer",
                }}
                className="d-flex  justify-content-center align-content-center py-3"
                onClick={() => {
                  serverInstance
                    .post("/chat/access", {
                      userId: user._id,
                      other: item?.user._id,
                    })
                    .then(() => {
                      navigate("/chatt", { replace: true });
                    });
                }}
              >
                Chat
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
      <Footer />
    </div>
  );
};

export default Detail;
