import React from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Party from "./Party/Party";
import { useLocation } from "react-router-dom";
function PartyMain() {
  const location = useLocation();
  console.log(location, "location");
  return (
    <>
      <Header />
      <Party cards={location.state.cards} hede={location.state.d} />
      <Footer />
    </>
  );
}

export default PartyMain;
