import React from 'react'
import Header from '../Header/Header'
import Calendar from  "../Calendar/FirstSection"
import Footer from '../Footer/Footer'

function Homemain() {
  return (
    <div>
        <Header/>
        <Calendar/>
      <Footer/>
    </div>
  )
}

export default Homemain
