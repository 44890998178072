import React, { useEffect, useState } from "react";
import "./Header.css";
import Container from "react-bootstrap/Container";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@chakra-ui/button";
import { useDisclosure } from "@chakra-ui/hooks";
import { Input } from "@chakra-ui/input";
import { Box, Text } from "@chakra-ui/layout";
import Swal from "sweetalert2";
import {
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from "@chakra-ui/menu";
import NotificationBadge from "react-notification-badge";
import { Effect } from "react-notification-badge";
import { getSender } from "../../config/ChatLogics";
import UserListItem from "./UserListItem";
import { ChatState } from "../../Context/ChatProvider";
import { BellIcon, ChevronDownIcon } from "@chakra-ui/icons";
import log from "../Images/logo.png";
export default function Header() {
  const {
    setSelectedChat,
    user,
    notification,
    setNotification,
    chats,
    setChats,
  } = ChatState();
  let navigate = useNavigate();
  const [token, setAdminToken] = useState(sessionStorage?.getItem("token"));
  useEffect(() => {}, [token]);

  const [search, setSearch] = useState("Item A");
  const handelsearch = () => {
    if (search === "") {
      Swal.fire("please enter for search", "", "error");
      return;
    } else {
      navigate(`/search`, {
        replace: true,
        state: { data: search },
      });
    }
  };
  return (
    <Navbar expand="lg">
      <Container fluid>
        <Navbar.Brand href="#">
          <Link to={"/RenteeMain"}>
            <div style={{ color: "#cc5500" }}>
              <img src={log} style={{ width: "150px" }} />
            </div>
          </Link>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="navbarScroll" />

        <Navbar.Collapse id="navbarScroll">
          <Nav className="me-auto my-2 my-lg-0" navbarScroll>
            <div className="Input-bar">
              <input
                className="Input-Search"
                placeholder="Type here"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <button className="Search-button" onClick={() => handelsearch()}>
                Search
              </button>
            </div>
            <div className="renterBtn">
              <Link style={{ textDecoration: "none" }} to="/saved">
                <Nav.Link href="#action1">
                  <div
                    style={{
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                  >
                    Favorite
                  </div>{" "}
                </Nav.Link>
              </Link>
              <Link style={{ textDecoration: "none" }} to="/chatt">
                <Nav.Link href="#action1">
                  <div
                    style={{
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                  >
                    Chat
                  </div>{" "}
                </Nav.Link>
              </Link>
              {token !== null ? (
                <Nav.Link href="#action1">
                  <div
                    onClick={() => {
                      sessionStorage.removeItem("token");
                      navigate("/LoginPage");
                    }}
                    style={{
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                  >
                    Logout
                  </div>{" "}
                </Nav.Link>
              ) : (
                <Link style={{ textDecoration: "none" }} to="/LoginPage">
                  <Nav.Link href="#action1">Login</Nav.Link>
                </Link>
              )}

              <Link to={"/Registration"}>
                <Nav.Link href="#action1"> SignUp</Nav.Link>
              </Link>
              <Menu>
                <MenuButton p={1}>
                  <NotificationBadge
                    count={notification.length}
                    effect={Effect.SCALE}
                  />
                  <BellIcon fontSize="2xl" m={1} />
                </MenuButton>
                <MenuList pl={2}>
                  {!notification.length && "No New Messages"}
                  {notification.map((notif) => (
                    <MenuItem
                      key={notif._id}
                      onClick={() => {
                        setSelectedChat(notif.chat);
                        setNotification(
                          notification.filter((n) => n !== notif)
                        );
                      }}
                    >
                      {notif.chat.isGroupChat
                        ? `New Message in ${notif.chat.chatName}`
                        : `New Message from ${getSender(
                            user,
                            notif.chat.users
                          )}`}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
              <div>
                <Link to={"/AddListItem"}>
                  <button
                    className="Renter-Secbutton"
                    style={{
                      background: "#463326",
                      color: "white",
                      width: "200px",
                    }}
                  >
                    Become a Renter
                  </button>
                </Link>
              </div>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
