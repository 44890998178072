import { Avatar } from "@chakra-ui/avatar";
import { Tooltip } from "@chakra-ui/tooltip";
import ScrollableFeed from "react-scrollable-feed";
import {
  isLastMessage,
  isSameSender,
  isSameSenderMargin,
  isSameUser,
} from "../../config/ChatLogics";
import { ChatState } from "../../Context/ChatProvider";
import { BASE_URL } from "../../axiosInsatnce";
const ScrollableChat = ({ messages }) => {
  const { user } = ChatState();

  return (
    <ScrollableFeed>
      {messages &&
        messages.map((m, i) => {
          console.log(
            messages[i - 1]?.sender._id === user?._id,
            "messages, m, i, user._id"
          );
          return (
            <div
              style={
                m?.sender._id === user?._id
                  ? { display: "flex", flexDirection: "row-reverse" }
                  : { display: "flex" }
              }
              key={m._id}
            >
              {
                <Tooltip
                  label={m.sender.userName}
                  placement="bottom-start"
                  hasArrow
                >
                  <Avatar
                    mt="7px"
                    mr={1}
                    size="sm"
                    cursor="pointer"
                    name={m.sender.userName}
                    src={m.sender.pic}
                  />
                </Tooltip>
              }
              <span
                style={{
                  backgroundColor: `${
                    m.sender._id === user._id ? "#BEE3F8" : "#B9F5D0"
                  }`,
                  marginLeft: isSameSenderMargin(messages, m, i, user._id),
                  marginTop: isSameUser(messages, m, i, user._id) ? 3 : 10,
                  borderRadius: "5px",
                  padding: "5px 10px",
                  maxWidth: "75%",
                }}
              >
                <div style={{ fontWeight: "bold" }}>{m.sender.userName}</div>
                {m.content}
                {m?.image && (
                  <img src={BASE_URL + "/" + m?.image} className="imageChat" />
                )}
              </span>
            </div>
          );
        })}
    </ScrollableFeed>
  );
};

export default ScrollableChat;
