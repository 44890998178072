import React from 'react'
import styles from '../TermsCondition/TermCondition.module.css'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
const TermsCondition = () => {
  return (
    <>
    <Header/>
    <div className={styles.privacyMain}>
      <div className={styles.privacyContainer}>
        <div className={styles.privacyMainheading}>
        Terms and Conditions 

        </div>
       
        <div className={styles.privacyMaintext}>
        These Terms and Conditions ("Agreement") are a legally binding contract between you (the "User," 
"Renter," or "Provider") and RENTVIZ. By accessing, browsing, or using our two-sided rental platform, 
you agree to comply with and be bound by the terms and conditions outlined in this Agreement. If you 
do not agree with these terms, please do not use our platform.
        </div>

        <div className={styles.privacyheading1}>
        **1. Registration and User Accounts**

        </div>
        <div className={styles.privacytext1}>
        1.1. **Eligibility**: To use our platform, you must be at least 18 years old or the legal age in your 
jurisdiction. By creating an account, you represent and warrant that you meet these criteria.
        </div>
        <div className={styles.privacytext1}>
        1.2. **Account Creation**: To access certain features, you may be required to create an account. You 
agree to provide accurate and complete information during the registration process and keep your login 
credentials confidential. You are solely responsible for the activity that occurs on your account
        </div>
      
        <div className={styles.privacyheading1}>
        **2. Listings and Rentals**


        </div>
        <div className={styles.privacytext1}>
        2.1. **Listings**: As a Provider, you can create listings for items or services you wish to rent. All listings 
must be accurate, complete, and comply with our listing guidelines.

        </div>
        <div className={styles.privacytext1}>
        2.2. **Rentals**: As a Renter, you can browse listings, request rentals, and agree to the terms set by 
Providers. Rentals are subject to availability, and a binding rental agreement is formed when both parties 
agree to the terms.
        </div>
       
        <div className={styles.privacyheading1}>
        **3. Rental Agreements**
        </div>
        <div className={styles.privacytext1}>
        3.1. **Payment**: Renter agrees to pay the rental fee, security deposit (if applicable), and any additional 
charges specified by the Provider. Payment may be processed through our platform, and additional 
terms may apply.
        </div>
        <div className={styles.privacytext1}>
        3.2. **Cancellation**: Cancellation policies may vary among Providers. Renter is responsible for 
understanding and adhering to the cancellation policy set by the Provider at the time of booking.

        </div>
        <div className={styles.privacytext1}>
        3.3. **Liability**: Both Renter and Provider agree to follow all relevant laws and regulations. Renter 
assumes responsibility for the rented item during the rental period and is liable for any damage or loss 
caused during their possession      </div>
        <div className={styles.privacyheading1}>
        **4. Communication and Feedback**

        </div>
        <div className={styles.privacytext1}>
        4.1. **Communication**: Users agree to communicate through our platform for all rental-related 
matters. Sharing personal contact information is discouraged and may violate these terms.


        </div>
        <div className={styles.privacytext1}>
        4.2. **Feedback**: Users may leave reviews and feedback regarding their rental experiences. These 
reviews must be honest, accurate, and respectful.
        </div>
        <div className={styles.privacyheading1}>
        **5. Privacy and Data**

        </div>
        <div className={styles.privacytext1}>
        5.1. **Privacy Policy**: Users agree to our Privacy Policy, which outlines how we collect, use, and 
protect your personal information.
        </div>
       
        <div className={styles.privacyheading1}>
        **6. Prohibited Activities**

        </div>
        <div className={styles.privacytext1}>
        6.1. Users must not engage in any of the following activities:

        </div>
       
        <div className={styles.privacytext1} style={{paddingLeft:"16px"}}>
        a. Violating any applicable laws or regulations.

        </div>
        <div className={styles.privacytext1} style={{paddingLeft:"16px"}}>
        b. Creating fraudulent listings or accounts.

        </div>
        <div className={styles.privacytext1} style={{paddingLeft:"16px"}}>
        c. Attempting to access, tamper with, or disrupt the platform's security or functionality.

        </div>
        <div className={styles.privacytext1} style={{paddingLeft:"16px"}}>
        d. Harassing, defaming, or discriminating against other Users.

        </div>
        <div className={styles.privacytext1} style={{paddingLeft:"16px"}}>
        e. Breaching the terms of rental agreements.

        </div>
        <div className={styles.privacyheading1}>
        **7. Termination**
        </div>
        <div className={styles.privacytext1}>
        7.1. We reserve the right to terminate or suspend your account and access to our platform at our 
discretion, without notice, for violations of these terms or any other reason.
        </div>
        <div className={styles.privacyheading1}>
        **8. Disclaimer and Limitation of Liability**
        </div>
        <div className={styles.privacytext1}>
        8.1. Our platform is provided "as is," and we make no warranties, express or implied, regarding its 
operation or availability
        </div>
        <div className={styles.privacytext1}>
        8.2. We are not liable for any direct or indirect damages, losses, or claims arising from your use of the 
platform or any rental transactions.
        </div>
        <div className={styles.privacyheading1}>
        **9. Changes to Terms**

        </div>
        <div className={styles.privacytext1}>
        9.1. We may update these terms at our discretion. Users will be notified of any changes, and continued 
use of the platform after changes signify your acceptance of the updated terms.
        </div>
        <div className={styles.privacyheading1}>
        **10. Governing Law**

        </div>
        <div className={styles.privacytext1}>
        10.1. This Agreement is governed by and construed in accordance with the applicable laws of the United 
States. Any disputes are subject to the exclusive jurisdiction of the courts in the United States

        </div>
        <div className={styles.privacytext1} style={{paddingTop:"40px", paddingBottom:"40px"}}>
        By using our platform, you agree to these Terms and Conditions. If you have any questions or concerns, 
please contact us at info@rentviz.com.

        </div>
       
        
      </div>
    </div>
    <Footer/>
    </>
  )
}

export default TermsCondition
