import Party from "../../Images/Party.png"
import ben from "../../Images/ben.png"
import bluecar from "../../Images/bluecar.png"
import camera from "../../Images/camera.png"
import cloths from "../../Images/cloths.png"
import cycle2 from "../../Images/cycle2.png"
import reception from "../../Images/reception.png"


const ListItemArr = [
    {
        cardsImg:Party,
        btnName:"Party"        
    },
    {
        cardsImg:reception,
        btnName:"Wedding event"  
    },
    {
        cardsImg:cycle2,
        btnName:"Bike"  
    },
    {
        cardsImg:camera,
        btnName:"Camera Lens"  
    },
    {
        cardsImg:cloths,
        btnName:"Clothing"  


    },
    {
        cardsImg:ben,
        btnName:"RV"  


    },
    {
        cardsImg:bluecar,
        btnName:"car"  

    },
    {
        cardsImg:Party,
        btnName:"Party"        
    },
    {
        cardsImg:reception,
        btnName:"Wedding event"  
    },
    {
        cardsImg:cycle2,
        btnName:"Bike"  
    },
]

export default ListItemArr;